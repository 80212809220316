import {v4 as uuidv4} from 'uuid';

export const STEP_TYPE_OPINION_SCALE = 'OPINION_SCALE';
export const STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT =
  'MULTIPLE_CHOICE_SINGLE_SELECT';
export const STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT =
  'MULTIPLE_CHOICE_MULTI_SELECT';
export const STEP_TYPE_TEXT_LONG = 'TEXT_LONG';
export const STEP_TYPE_SLIDER = 'SLIDER';
export const STEP_TYPE_TYPEFORM = 'TYPEFORM';
export const STEP_TYPE_NPS = 'NPS';
export const STEP_TYPE_CONCEPT_TEST = 'CONCEPT_TEST';
export const STEP_TYPE_TEXT_BLOCK = 'TEXT_BLOCK';
export const STEP_TYPE_SUCCESS = 'SUCCESS';
export const STEP_TYPE_INTERVIEW = 'INTERVIEW';
export const STEP_TYPE_CHECKLIST = 'CHECKLIST';

export const F_STEP_CTA_IN_NEW_TAB = 1;
export const F_STEP_HEIGHT_CUSTOM = 2;
export const F_STEP_DISCOVERY_STEP_REQUIRED = 4;
export const F_STEP_IS_SELECTING_PRESET = 8;
export const F_STEP_CHECKLIST_STEP_IS_DISMISSIBLE = 16;
export const F_STEP_CHECKLIST_STEP_COMPLETION_ORDERED = 32;

export const CONCEPT_TEST_DOMAIN_FIGMA = 'figma.com';
export const CONCEPT_TEST_DOMAIN_INVIS = 'invis.io';
export const CONCEPT_TEST_DOMAIN_INVISIONAPP = 'invisionapp.com';
export const CONCEPT_TEST_DOMAIN_MAZE = 'maze.co';

export const BLOCK_TYPE_TITLE = 'TITLE';
export const BLOCK_TYPE_BODY = 'BODY';
export const BLOCK_TYPE_MEDIA = 'MEDIA';
export const BLOCK_TYPE_USER = 'USER';
export const BLOCK_TYPE_LABEL = 'LABEL';
export const BLOCK_TYPE_STEPPER = 'STEPPER';
export const BLOCK_TYPE_PRIMARY_CTA = 'PRIMARY_CTA';
export const BLOCK_TYPE_SECONDARY_CTA = 'SECONDARY_CTA';
export const BLOCK_TYPE_CHOICE = 'CHOICE';
export const BLOCK_TYPE_NPS = 'NPS';
export const BLOCK_TYPE_SLIDER = 'SLIDER';
export const BLOCK_TYPE_OPINION = 'OPINION';
export const BLOCK_TYPE_INTERVIEW = 'INTERVIEW';
export const BLOCK_TYPE_OPEN_QUESTION = 'OPEN_QUESTION';
export const BLOCK_TYPE_ANIMATION = 'ANIMATION';
export const BLOCK_TYPE_CONCEPT = 'CONCEPT';
export const BLOCK_TYPE_HOTSPOT = 'HOTSPOT';
export const BLOCK_TYPE_HINT = 'HINT';
export const BLOCK_TYPE_TARGET = 'TARGET';
export const BLOCK_TYPE_CHECKLIST = 'CHECKLIST';
export const BLOCK_TYPE_CHECKLIST_HEADER = 'CHECKLIST_HEADER';
export const BLOCK_TYPE_CHECKLIST_HEADER_TITLE = 'CHECKLIST_HEADER_TITLE';
export const BLOCK_TYPE_CHECKLIST_HEADER_DESCRIPTION =
  'CHECKLIST_HEADER_DESCRIPTION';
export const BLOCK_TYPE_CHECKLIST_HEADER_PROGRESS = 'CHECKLIST_HEADER_PROGRESS';
export const BLOCK_TYPE_CHECKLIST_TASK_LIST = 'CHECKLIST_TASK_LIST';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM = 'CHECKLIST_TASK_ITEM';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM_CHECKBOX =
  'CHECKLIST_TASK_ITEM_CHECKBOX';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM_TITLE = 'CHECKLIST_TASK_ITEM_TITLE';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM_DESCRIPTION =
  'CHECKLIST_TASK_ITEM_DESCRIPTION';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM_MEDIA = 'CHECKLIST_TASK_ITEM_MEDIA';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM_PRIMARY_CTA =
  'CHECKLIST_TASK_ITEM_PRIMARY_CTA';
export const BLOCK_TYPE_CHECKLIST_TASK_ITEM_SECONDARY_CTA =
  'CHECKLIST_TASK_ITEM_SECONDARY_CTA';
export const BLOCK_TYPE_CHECKLIST_TRIGGER = 'CHECKLIST_TRIGGER';
export const BLOCK_TYPE_CHECKLIST_DISMISS = 'CHECKLIST_DISMISS';
export const BLOCK_TYPE_DISMISS_CROSS = 'DISMISS_CROSS';

export const CONCEPT_TEST_DOMAINS = [
  CONCEPT_TEST_DOMAIN_FIGMA,
  CONCEPT_TEST_DOMAIN_INVIS,
  CONCEPT_TEST_DOMAIN_INVISIONAPP,
  CONCEPT_TEST_DOMAIN_MAZE,
];

export const STEP_CONDITION_ACTION_TYPE_GO_TO_STEP = 'GO_TO_STEP';
export const STEP_CONDITION_ACTION_TYPE_RUN_JS_CODE = 'RUN_JS_CODE';
export const STEP_CONDITION_ACTION_TYPE_NAVIGATE_TO = 'NAVIGATE_TO';
export const STEP_CONDITION_ACTION_TYPE_DISMISS = 'DISMISS';
export const STEP_CONDITION_ACTION_TYPE_LAUNCH_EXPERIENCE = 'LAUNCH_EXPERIENCE';
export const STEP_CONDITION_ACTION_TYPE_OPEN_POST = 'OPEN_POST';
export const STEP_CONDITION_ACTION_TYPE_BOOK_INTERVIEW = 'BOOK_INTERVIEW';
export const STEP_CONDITION_ACTION_TYPE_EXPAND_CHECKLIST = 'EXPAND_CHECKLIST';
export const STEP_CONDITION_ACTION_TYPE_MARK_CHECKLIST_TASK_COMPLETED =
  'MARK_CHECKLIST_TASK_COMPLETED';
export const STEP_CONDITION_ACTION_TYPE_SKIP_TASK = 'SKIP_TASK';
export const STEP_CONDITION_ACTION_TYPE_SNOOZE = 'SNOOZE';

export const F_STEP_TRIGGER_ACTION_OPEN_IN_NEW_TAB = 1;

export const createStepObj = (type, props = {}) => {
  const {prototypesSteps = [], options = [], ...rest} = props;

  return {
    uid: uuidv4(),
    name: 'New step',
    type,
    question: '', // deprecated
    additionalInformation: '', // deprecated
    mdContent: '', // deprecated
    mdRawContent: '', // deprecated
    interactiveLink: '', // deprecated
    conceptThumbnailUrl: null, // deprecated
    conceptThumbnailFileId: null, // deprecated
    texts: [],
    blocks: [],
    ...([
      STEP_TYPE_MULTIPLE_CHOICE_MULTI_SELECT,
      STEP_TYPE_MULTIPLE_CHOICE_SINGLE_SELECT,
    ].includes(type)
      ? {
          options:
            options.length > 0
              ? options.map((o, i) =>
                  createStepOptionObj({indexOrder: i, ...o})
                )
              : [
                  createStepOptionObj({indexOrder: 0}),
                  createStepOptionObj({indexOrder: 1}),
                ],
        }
      : {}),
    ...(props.prototypesSteps?.length > 0
      ? {
          prototypes: [
            {
              uid: uuidv4(),
              steps:
                prototypesSteps.length > 0
                  ? prototypesSteps
                  : [createStepObj(STEP_TYPE_OPINION_SCALE)],
            },
          ],
        }
      : {prototypes: []}),
    style: getDefaultStepStyle(),
    flags: 0,
    ...rest,
  };
};

export const createStepOptionObj = (props = {}) => {
  return {uid: uuidv4(), content: '', labels: [], ...props};
};

export const getDefaultStep = (props = {}) => {
  return {
    uid: uuidv4(),
    blocks: [],
    triggers: [],
    flags: 0,
    style: getDefaultStepStyle(),
    ...(props.prototypesSteps?.length > 0
      ? {
          prototypes: [
            {
              uid: uuidv4(),
              steps:
                props.prototypesSteps.length > 0 ? props.prototypesSteps : [],
            },
          ],
        }
      : {prototypes: []}),
    ...props,
  };
};

export const getDefaultStepStyle = (props = {}) => {
  return {
    shadow: {color: '#0000000f', x: 0, y: 8, blur: 12},
    borderRadius: 12,
    background: {
      type: 'color', // gradient|color
      animated: false,
      primaryColor: '#FFFFFF',
      secondaryColor: null,
    },
    overlay: null,
    animationIn: 'fade',
    animationOut: 'fade',
    width: null,
    height: null,
    margin: 0,
    withElementsAnimations: true,
    backgroundColor: '#FFFFFF',
    borderColor: '#0000000f',
    ...props,
  };
};
