import Button from 'components/Button';
import {toastDanger} from 'components/Toaster';
import {BuilderContext} from 'contextes/builder';
import {errorHelpers} from 'helpers';
import {hasFlag} from 'helpers/bitwise';
import {useContext, useState} from 'react';
import {InAppBuilderContext} from 'scenes/Pushes/context';
import {fileService} from 'services';
import {F_BOOST_SLOT_DOT, F_BOOST_SLOT_TOOLTIP} from 'services/evolution';
import {Swaler} from 'swaler';
import ManualSelectorEditor from '../../../ManualSelectorEditor';
import './_Styles.scss';

const logger = new Swaler('ElementSelector');

export const ElementSelector = ({
  data,
  onChange,
  isInput,
  withMultiple,
  withElementsCount,
  onSelectElement = null,
}) => {
  const {goToEditInApp, stopInAppEditing} = useContext(InAppBuilderContext);
  const {controlledEvolution: evolution} = useContext(BuilderContext) || {};

  const [isUploading, setIsUploading] = useState(false);

  const {file, querySelector, querySelectorManual} = data;

  const isTooltip = hasFlag(F_BOOST_SLOT_TOOLTIP, evolution?.boostFlags);
  const isHotspot = hasFlag(F_BOOST_SLOT_DOT, evolution?.boostFlags);
  const hasTargetElement = isTooltip === true || isHotspot === true;
  const hasPinnedElement = evolution?.boostedQueryselector != null;
  const hasPinnedElementManual =
    !!evolution?.querySelectorManual?.elementText ||
    !!evolution?.querySelectorManual?.cssSelector;
  const hasPreviewFile = !!evolution?.file?.publicUrl;

  const uploadFile = async (file) => {
    if (file == null) {
      return;
    }
    const response = await fetch(file);
    const blob = await response.blob();
    const uploadableFile = new File([blob], 'upload.png', {type: 'image/png'});

    try {
      const uploadedFile = await fileService.uploadPublicFile({
        file: uploadableFile,
      });
      return uploadedFile;
    } catch (err) {
      const {code, title, message, actions} = errorHelpers.parseError(err);

      logger.error('Failed to upload file', code);
      toastDanger([title, message], {actions});
      return null;
    }
  };

  const handleElementSelected = async (data) => {
    setIsUploading(true);
    const file = await uploadFile(data.image);
    setIsUploading(false);

    onChange({
      querySelector: data.querySelector,
      querySelectorManual: data.querySelectorManual,
      ...(file != null
        ? {
            file,
          }
        : {
            file: null,
          }),
    });
  };

  const handleUsePinnedElement = () => {
    onChange({
      querySelector: evolution?.boostedQueryselector,
      querySelectorManual: evolution?.querySelectorManual,
      file: evolution?.file,
    });
  };

  const handleGoToEditInApp = () => {
    goToEditInApp({
      data: {
        title: 'Select element',
      },
      handshakeData: {
        type: 'SET_MODE',
        data: {
          mode: 'ELEMENT_SELECTOR_BUILDER',
          onlyInput: isInput,
        },
      },
      onChildCommunication: (message) => {
        if (message?.type === 'TARGET_ELEMENT_SELECTED') {
          handleElementSelected(message.data);
          stopInAppEditing();
        }
        if (message?.type === 'EMBEDDED_BUILDER_CLOSE') {
          stopInAppEditing();
        }
      },
    });
  };

  const wording = isInput ? 'input' : 'element';

  return (
    <div className="element-selector-wrapper">
      {querySelectorManual?.elementText != null ||
      querySelectorManual?.cssSelector != null ? (
        <ManualSelectorEditor
          querySelectorManual={querySelectorManual}
          onChange={(newQuerySelectorManual) => {
            onChange({
              querySelectorManual: {
                ...querySelectorManual,
                ...newQuerySelectorManual,
              },
              querySelector: querySelector,
              file: file,
            });
          }}
          onSwitchToAuto={() => {
            onChange({
              querySelectorManual: null,
              querySelector: querySelector,
              file: file,
            });
          }}
          withText={!isInput}
          withMultiple={withMultiple}
          withElementsCount={withElementsCount}
        />
      ) : !querySelector ? (
        <>
          <div className="selector-auto-group">
            <div
              className="selector-auto-option"
              onClick={() => {
                if (onSelectElement != null) {
                  onSelectElement();
                } else {
                  handleGoToEditInApp();
                }
              }}>
              <div className="icon-wrapper">
                <i className="icon-target" />
              </div>
              <div className="subtitle-4 n-800">Pick a new {wording}</div>
            </div>
            {hasTargetElement === true &&
              (hasPinnedElement === true ||
                hasPinnedElementManual === true) && (
                <div
                  className="selector-auto-option"
                  onClick={handleUsePinnedElement}>
                  {hasPinnedElement === true &&
                    hasPinnedElementManual !== true && (
                      <div className="preview-wrapper">
                        {hasPreviewFile === true ? (
                          <img src={evolution.file?.publicUrl} alt="element" />
                        ) : (
                          <div className="image-not-found body-4 n-700">
                            <i className="icon-image" />
                            No preview found
                          </div>
                        )}
                      </div>
                    )}
                  <div className="subtitle-4 n-800">
                    Use step pinned {wording}
                  </div>
                </div>
              )}
          </div>
          <div className="select-element-btn-wrapper">
            <Button
              thin
              iconLeft="isax isax-edit-2"
              onClick={() =>
                onChange({
                  querySelectorManual: {
                    elementText: '',
                    cssSelector: '',
                    matchElement: 'first',
                  },
                  querySelector: querySelector,
                  file: file,
                })
              }>
              Or go manual
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="selector-preview-wrapper">
            {file?.publicUrl != null ? (
              <img src={file?.publicUrl} alt="element" />
            ) : (
              <div className="image-not-found">
                <i className="icon-image" />
                No preview found
              </div>
            )}
            <div className="query-selector body-3 n-700">{querySelector}</div>
          </div>
          <div className="select-element-btn-wrapper">
            <Button
              thin
              primary
              onClick={() => {
                if (onSelectElement != null) {
                  onSelectElement();
                } else {
                  handleGoToEditInApp();
                }
              }}
              iconLeft="icon-target">
              Reselect {wording}
            </Button>
            <Button
              thin
              iconLeft="isax isax-edit-2"
              onClick={() =>
                onChange({
                  querySelectorManual: {
                    elementText: '',
                    cssSelector: querySelector,
                    matchElement: 'first',
                  },
                  querySelector: querySelector,
                  file: file,
                })
              }>
              Edit selector
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
