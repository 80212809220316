import Button from 'components/Button';
import {BuilderContext} from 'contextes/builder';
import React, {useContext, useEffect} from 'react';
import {
  MODE_NAVIGATOR,
  MODE_TRIGGERS,
} from 'scenes/PokeBuilder/components/PokeBuilderSidebar';
import {EVOLUTION_TYPE_SURVEY, EVOLUTION_TYPE_TOUR} from 'services/evolution';
import './_Styles.scss';

const BuilderModeSwitch = () => {
  const {mode, setMode, selectedStepId, evolution, setSelectedStepId} =
    useContext(BuilderContext);

  const isFlowMode = mode === MODE_TRIGGERS || mode == null;
  const isContentMode = !isFlowMode;

  const isTour = evolution?.type === EVOLUTION_TYPE_TOUR;
  const isSurvey = evolution?.type === EVOLUTION_TYPE_SURVEY;

  useEffect(() => {
    if (mode !== MODE_NAVIGATOR) {
      return;
    }

    if (selectedStepId == null) {
      if (isTour) {
        const tourSteps = evolution?.tourSteps || [];
        tourSteps.forEach((ts) => {
          const [tourIndexOrder] = (ts.tourStepInfo || '0;0;0').split(';');
          ts.tourIndexOrder = parseInt(tourIndexOrder, 10);
          ts.steps.sort((a, b) => a.indexOrder - b.indexOrder);
        });
        tourSteps.sort((a, b) => a.tourIndexOrder - b.tourIndexOrder);
        const steps = tourSteps.reduce(
          (steps, ts) => steps.concat(ts.steps.filter((s) => !s.removed)),
          []
        );
        setSelectedStepId(steps[0]?.uid);
      } else if (isSurvey) {
        const steps = evolution?.steps?.filter((step) => !step.removed);
        setSelectedStepId(steps[0]?.uid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  return (
    <div className="builder-mode-switch">
      <Button
        className={mode === 'navigator' ? 'is-active' : ''}
        onClick={() => {
          if (isFlowMode !== true) {
            setMode(MODE_TRIGGERS);
          }
        }}
        iconLeft={isFlowMode ? 'isax isax-routing-25' : 'isax isax-routing-2'}
        light={isFlowMode}
        thin>
        Flow
      </Button>
      <Button
        className={mode === 'triggers' ? 'is-active' : ''}
        onClick={() => {
          if (isContentMode !== true) {
            setMode(MODE_NAVIGATOR);
          }
        }}
        iconLeft={
          isContentMode ? 'isax isax-designtools5' : 'isax isax-designtools'
        }
        light={isContentMode}
        thin>
        Content
      </Button>
    </div>
  );
};

export default BuilderModeSwitch;
